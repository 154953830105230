.services{
    padding:30px;
}
.services-heading h1{
    font-size:44px;
    font-weight: 600;
    display:flex;
    justify-content:center;
    align-items: center;
    margin:60px auto;
}

.services-body{
    display:flex;
    
}

.services-flex{
    display:flex;
    flex-direction:column;
    width:80%;
}

.services-content{
    display:flex;
    padding:20px;
    margin:20px;
    justify-content: center;
    align-items: center;
    background-color: #FAFAFA;
    transition: transform 1s;
}

.services-content:hover{
    transform: scale(1.1);
}

.serv-1{
    background-color: #FFC5C5;
}
.serv-2{
    background-color: #1BD697;
}
.serv-3{
    background-color: #6699FF;
}
.serv-4{
    background-color: #DAA7E3;
}
.serv-5{
    background-color: #FFC370;
}
.serv-6{
    background-color: #A0FFDD;
}

.services-content img{
    width: auto;
    height:60px;
}

.services-content h1{
    font-size:22px;
    margin:5px 0;
    font-weight: 600;
}

.services-content p{
    font-size:17px;
    font-weight: 200;
}

.services-content-div{
    margin-left: 30px;
}

@media (max-width:800px) {
    .services{
        padding:20px;
    }
    .services-heading h1{
        font-size:24px;
        margin:30px auto;
    }
    
    .services-flex{
        width:100%;
    }

    .services-body{
        display:flex;
        flex-direction:column;
        text-align: center;
        
    }
    
    .services-content{
        padding:10px;
        margin:10px;
        flex-direction: column;
    }
    
    .services-content img{
        width: auto;
        height:50px;
    }
    
    .services-content h1{
        font-size:20px;
        font-weight: 600;
    }
    
    .services-content p{
        font-size:14px;
        font-weight: 400;
    }
    
    .services-content-div{
        margin: 20px;
    }
}