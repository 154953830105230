.TextBanner img{
    max-width: 100%;
    position: absolute;
}

.TextBanner-content{
    position: relative;
    margin-bottom: 60px;
    font-weight: bold;
}

.TextBanner-heading{
    text-align: center;
    margin:30px 0;
}

.TextBanner-heading h1{
    font-size:46px;
}

.TextBanner-body{
    display: flex;
    justify-content: space-around;
    text-align: center;
    margin-top: 80px;
}

.TextBanner-div p{
    font-size: 23px;
}

.TextBanner-div h1{
    font-size:43px;
}

@media (max-width:1200px) {
    .TextBanner-heading h1{
        font-size:30px;
    }

    .TextBanner-div p{
        font-size: 24px;
    }
    
    .TextBanner-div h1{
        font-size:28px;
    }

}

@media (max-width:800px) {

    .TextBanner-content{
        margin: 100px 0 30px 0;
    }
    
    .TextBanner-heading{
        margin:20px 0;
    }
    
    .TextBanner-heading h1{
        font-size:20px;
        margin: 0 auto;
        width: 60%;
    }
    
    .TextBanner-body{
        margin-top: 40px;
    }
    
    .TextBanner-div p{
        font-size: 14px;
    }
    
    .TextBanner-div h1{
        font-size:16px;
    }
}