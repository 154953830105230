.why-testimonials{
    padding:30px;
}

.why-testimonials-heading{
    text-align: center;
    margin:80px 0;
}

.why-testimonials-content{
    display: flex;
    flex-direction: row;
    justify-content:space-around;
}

.why-testimonials-part{
    width:20%;
    text-align:center;
    background-color: #FAFAFA;
    padding: 40px;
    margin: 30px;
}

.why-active{
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.why-active img{
    border:5px solid #1BD697;
    border-radius: 100%;
    max-width: 100%;
}

.why-testimonials-part img{
    max-width:30%;
}