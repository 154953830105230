.students-div{
    display: flex;
    padding: 40px;
    background-color: #F8FAFC;
}

.students-content{
    display: flex;
    
}

.students-div-left{
    width:50%;
    margin: 0px 50px 40px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px 50px
    /* align-items: center; */
}
.students-div-left h1{
    font-size: 68px;
    font-weight: bold;
    width:80%;
}
.students-div-left p{
    font-size: 21px;
    width:70%;
    margin:20px 0;
}
.students-div-right{
    width:45%;
    padding: 40px 30px 10px 30px;
}

.students-div-left button{
    padding:10px 15px;
    border-radius: 5px;
    border:none;
    color:white;
    background: rgb(40,27,190);
    background: linear-gradient(90deg, rgba(40,27,190,1) 0%, rgba(46,255,217,1) 100%);
    font-size: 21px;
    width: 30%;
    margin-top: 40px;
    font-weight: bold;
    transition: transform 1s;
}

.students-div-left button:hover{
    transform: scale(1.1);
}

.students-content img{
    width:350px;
    height:300px;
    transition: transform 1s;
}

.students-content img:hover{
    transform: scale(1.1);
}

.pic-banner{
    display:flex;
    flex-direction: row;
}

.pic-banner-div{
    width:25%;
    height:50vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.pic-banner-div h1{
    font-size: 20px;
    font-weight: bold;
}
.pic-banner-div li{
    margin:12px 0;
}
.pic-banner-div p{
    font-size: 12px;
}

.followup{
    text-align: center;

}

.banner-img1 img{
    max-width: 60%;
}

.pic-banner-div button{
    color: white;
    padding:10px 20px;
    border:1px solid white;
    background:none;
    border-radius: 100px 10px 10px 10px;
    margin: 10px 0;
}

.pic1-banner{
    background-image: url(../../../../public/img/students/22.png);
    background-size: cover;
}

.pic2-banner img{
    max-width:20%;
    margin:20px 0;
}

.pic2-banner button{
    margin:20px 0;
}

@media (max-width:1200px){
    .students-content img{
        max-width:90%;
        height:160px;
    }
    
    .students-div-left h1{
        font-size: 28px;
    }
    .students-div-left p{
        font-size: 18px;
    }
    .students-div-left button{
        font-size: 21px;
        width: 60%;
    }
    .pic-banner-div{
        width:25%;
        height:20vh;
    }
}

@media (max-width:800px){
    .students-div{
        flex-direction: column;
        padding: 20px;
    }
    
    .students-content{
        display: flex;
        
    }
    
    .students-div-left{
        width:100%;
        margin: 20px auto;
        padding: 20px;
        text-align: center;
        /* align-items: center; */
    }
    .students-div-left h1{
        font-size: 28px;
        width:100%;
    }
    .students-div-left p{
        font-size: 16px;
        width:100%;
    }
    .students-div-right{
        width:100%;
        padding: 20px;
    }
    
    .students-div-left button{
        font-size: 21px;
        width: 80%;
        margin: 20px auto;
    }
    
    .students-content img{
        max-width:80%;
        margin: 0 auto;
        /* height:120px; */
        
    }

    
    
    .pic-banner{
        display:flex;
        flex-direction: column;
    }
    
    .pic-banner-div{
        width:100%;
        height: 500px;
    }
}