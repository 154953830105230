.whyrig {
  background-color: #f8fafc;
  padding: 50px 30px;
}

.whyrig-heading {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.whyrig-heading h1 {
  font-size: 60px;
  /* font-weight: bold; */
  /* RIGNSAM */
  color: #808080;

}

.whyrig-heading span{
    font-family: "Playfair Display";

}

.whyrig-div {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 50px 25% 50px auto;
  padding: 30px;
  background-color: #fff;
}

.whyrig-div:hover{
  background-color: #E1E1E1;
}

.rig-rev {
  margin: 50px auto 50px 25%;
  flex-direction: row-reverse;
  text-align: right;
}

.whyrig-content h1 {
  font-size: 27px;
  color: #1702a4;
  font-weight: 500;
  margin: 20px 0;
}
.whyrig-content p {
  font-size: 18px;
  font-weight: 300;
}

.whyrig-div img {
  padding: 30px;
  max-width: 30%;
}
.whyrig-div {
  width: 70%;
}

@media (max-width: 800px) {
  .whyrig {
    padding: 20px;
  }

  .whyrig-heading {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .whyrig-heading h1 {
    font-size: 30px;
    margin: 0 auto;
  }

  .whyrig-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 20px auto;
    padding: 30px;
    background-color: #fff;
  }
  
  .rig-rev {
    margin: 20px;
    margin: 20px auto;
    flex-direction: column;
    text-align: center;
  }

  .whyrig-content h1 {
    font-size: 27px;
    color: #1702a4;
    font-weight: 500;
    margin: 20px 0;
  }
  .whyrig-content p {
    font-size: 18px;
    font-weight: 300;
  }

  .whyrig-div img {
    padding: 30px;
    max-width: 100%;
  }
  .whyrig-div {
    width: 80%;
  }
}
