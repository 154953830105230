.affectus{
    margin:40px 0;
    padding:60px;
    display: flex;
    justify-content: space-around;
}

.affectus-body{
    width:100%;
}

.affectus-right{
    width:40%;
    padding:30px;
    margin: 0 auto;
}

.affectus-right-body img{
    max-width: 100%;
}

.affectus-body-flex{
    display: flex;
    background-color: #F2F2F2;
    width: 60%;
    padding:0px 20px;
}
.affectus-flex-1{
    display: flex;

}

.affectus-heading{
    margin:10px 0;
    width: 50%;
}

.affectus-heading h1{
    font-size: 41px;
    font-weight: bold;
    margin:20px 0;
}
.affectus-heading p{
    font-size: 16px;
    color: #6F6E6E;
}
.affectus-body-bottom h1{
    font-size: 20px;
}
.affectus-body-bottom li{
    font-size: 16px;
    font-weight: 500;
    margin-right: 30px;
    margin-bottom: 20px;
}

.affectus-body-bottom{
    padding:10px 0;
}

.affectus-right-body h1{
    font-size: 16px;
}
.affectus-right-body p{
    font-size: 13px;
}
.affectus-right-body h5{
    font-size: 10px;
    color:#1BD697;
}

.affect-btn{
    padding:10px 30px;
    font-size: 25px;
    color: white;
    border-radius:50px 10px 10px 10px;
    border: none;
    background: rgb(40,27,190);
    background: linear-gradient(90deg, rgba(40,27,190,1) 0%, rgba(46,255,217,1) 100%);
    margin: 30px 0;
}



@media (max-width: 1200px) {
    /* .affectus-right-body img{
        max-width: 30%;
    }
    .affectus-body-flex{
        display: flex;
        flex-direction: column;
    } */
}

@media (max-width: 800px) {
    .affectus{
        margin:20px;
        padding:10px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }
    
    .affectus-body{
        width:80%;
        margin: 0 auto;
    }
    
    .affectus-right{
        width:80%;
        margin: 0 auto;
    }
    
    .affectus-right-body img{
        max-width: 100%;
    }
    
    
    
    .affectus-heading{
        margin:10px auto;
        text-align:center;
        width:80%;
    }
    
    .affectus-heading h1{
        font-size: 26px;
    }
    .affectus-heading p{
        font-size: 18px;
    }
    .affectus-body-bottom h1{
        font-size: 20px;
    }
    .affectus-body-bottom li{
        font-size: 14px;
        margin: 20px;
        font-weight: 400;
    }
    
    .affectus-body-bottom{
        padding:10px 0;
    }
    
    .affectus-right-body h1{
        font-size: 16px;
    }
    .affectus-right-body p{
        font-size: 13px;
    }
    .affectus-right-body h5{
        font-size: 10px;
    }
    
    .affect-btn{
        padding:10px 20px;
        font-size: 16px;
        margin: 20px;
    }
    .affectus-flex-1{
        display: flex;
        flex-direction: column;
    }
    .affectus-body-flex{
        flex-direction: column;
        width: 80%;
        margin:0 auto;
    }
}