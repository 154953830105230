.testimonial{
    padding: 40px;
}

.testimonial-heading h1{
    font-size:50px;
    font-weight: bold;
    text-align: center;
    margin: 60px 0;
}

.testimonial-body{
    display: flex;
    align-items: center;
}

.testimonial-img{
    width:30%;
    margin:30px;
}

.testimonial-img img{
    max-width: 100%;
    border-radius: 15px;
}

.testimonial-content{
    margin: 20px 40px;
    width: 60%;
    text-align: left;
}

.testimonial-content p{
    font-size:20px;
}

.testimonial-content img{
    max-width: 60px;
    margin:20px 0;
}

.testimonial-content-para{
    margin:20px 0;
}

.testimonial-content-para p{
    margin-top:3px;
    font-size: 16px;
}
.testimonial-content-para h1{
    margin-bottom:3px;
    font-weight: 500;
    font-size: 22px;
}

.owl-carousel{
    padding: 10px 20px;
}

.owl-carousel .carousel-status{
    display: none;
}
.carousel.carousel-slider .control-arrow{
    /* background: rgb(0, 0, 0); */
    border-radius: 10px;
}

.carousel .control-next.control-arrow:before {
    border-left: 8px solid rgb(0, 0, 0);
}


@media (max-width: 800px){
    .testimonial{
        padding: 20px;
    }
    
    .testimonial-heading h1{
        font-size:28px;
        margin: 20px 0;
    }
    
    .testimonial-body{
        flex-direction: column;
    }
    
    .testimonial-img{
        width:100%;
        margin:20px;
    }
    
    .testimonial-img img{
        max-width: 100%;
    }
    
    .testimonial-content{
        margin: 30px;
        width: 100%;
        text-align: center;

    }
    
    .testimonial-content p{
        font-size:18px;
    }
    
    .testimonial-content img{
        max-width: 60px;
        margin:20px 0;
    }
    
    .testimonial-content-para{
        margin:20px 0;
    }
    
    .testimonial-content-para p{
        font-size: 16px;
    }
    .testimonial-content-para h1{
        font-size: 22px;
    }
}