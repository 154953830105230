.whyus-desc{
    display:flex;
    align-items: center;
}

.whyus-desc-img{
    width:50%;
}

.whyus-desc-img img{
    max-width: 100%;
}

.whyus-desc-body{
    width: 40%;
    padding:50px 70px;
}

.whyus-desc-body h1{
    font-size: 41px;
    font-weight: bold;
    margin: 30px 0;
}

.whyus-desc-body p,.whyus-desc-body li{
    color:#6F6E6E;
    font-size: 20px;
}

@media (max-width:800px){
    .whyus-desc{
        display:flex;
        flex-direction: column;
        align-items: center;
    }
    
    .whyus-desc-img{
        width:80%;
    }
    
    .whyus-desc-img img{
        max-width: 100%;
    }
    
    .whyus-desc-body{
        width: 100%;
        padding:30px;
    }
    
    .whyus-desc-body h1{
        font-size: 41px;
        text-align: center;
        margin: 30px 0;
    }
    
} 