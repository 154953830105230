.about-landing{
    padding:40px 0;
    margin:30px 0;
    width:100%;
}

.about-flex{
    display: flex;
    flex-direction: row;
    justify-content:space-around;
}

.about-content{
    margin:20px;
    width: 20%;
}

.about-content h1{
    font-size:25px;
    font-weight: 500;
    margin: 20px 0;
}

.about-content p{
    font-size:17px;
    font-weight: 200;
}

.about-content img{
    width: 100%;
    height:55%;
}

@media (max-width:800px){
    .about-landing{
        padding:20px;
        /* margin:20px; */
        width:100%;
    }
    
    .about-flex{
        display: flex;
        flex-direction: column;
        justify-content:space-around;
    }
    
    .about-content{
        margin:20px auto;
        width: 80%;
    }
    
    .about-content h1{
        font-size:22px;
        margin: 20px 0;
    }
    
    .about-content p{
        font-size:16px;
    }
    
    .about-content img{
        width: 100%;
        height:100%;
    }
    
}