.contact-img img{
    max-width: 100%;
}

.contact-img{
    position: absolute;
}

.contact-map-div iframe{
    width: 100%;
    height: 500px;
}

.contact-body{
    position: relative;
    padding: 100px 60px;
}

.contact-heading h1{
    text-align: center;
    font-size: 52px;
    font-weight: bold;

}

.contact-body-flex{
    display: flex;
    justify-content:space-around;
    margin:70px auto;
}

.contact-content{
    text-align: center;
    width:20%;
    margin:60px 0;
}

.contact-content img{
    height: 90px;
    margin:20px auto;
}

.contact-content h1{
    font-size:24px;
    margin:10px 0;
    font-weight:600;
}

.contact-map-div{
    display: flex;
    flex-direction: column;
    justify-content:center;
    align-items:center;
    margin:40px auto;
}

.contact-map-div iframe{
    border-radius: 10px;
    transition: transform 1s;

}
.contact-map-div iframe:hover{
    transform: scale(1.1);
}

.contact-map-heading h1{
    font-size:40px;
    font-weight: bold;
    margin: 30px 0;
}

@media (max-width: 1000px){
    .contact-map-div iframe{
        width: 100%;
        height: 500px;
    }
}

@media (max-width:800px){
    
    .contact-body{
        padding:100px 20px 40px 20px;
    }
    
    .contact-heading h1{
        font-size: 26px;  
    }
    
    .contact-body-flex{
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin:30px auto;
    }
    
    .contact-content{
        width:80%;
        margin:30px 0;
    }

    .contact-map-div iframe{
        width: 100%;
        height: 400px;
    }

}