.header-about img{
    max-width: 100%;
    padding-top:60px;
}

.header-div{
    margin:80px 0;
}

.header-heading{
    text-align: center;
    margin: 70px 0;
    font-weight:500;
}

.header-heading h1{
    font-size:50px;
    font-weight: bold;
}

.header-body{
    display: flex;
    flex-direction: row;
    justify-content:space-around;
    margin: 30px 0;
    align-items: center;
    text-align: center;
}

.header-flex{
    width: 26%;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    padding:45px;
    border-radius: 10px;
    transition: transform 1s;
}

.header-flex img{
    margin:20px auto;
}

.header-flex h1{
    font-size:32px;
    font-weight: 600;
    margin: 10px 0;
}

.header-flex:hover{
    transform: scale(1.1);
}

.header-para{
    padding: 80px;
}

.header-para-heading{
    text-align: center;
    margin:40px 0;
}
.header-para-heading h1{
    background: -webkit-linear-gradient(#FA4ABE, #2006CD);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 50px;
    font-weight:bold;
}

.header-para-div1{
    text-align: center;
    margin:30px;
    width: 40%;
}

.header-para-div1 h1{
    font-weight: 400;
    font-size: 20px;
}
.header-para-div1 p{
    font-size: 24px;
    margin:0;
}

.header-para-div1 img{
    max-width: 60%;
    border-radius: 15px;
    margin: 10px auto;
}

.header-para-div2 p span{
    font-size: 24px;
}

.header-para-content{
    display: flex;
    flex-direction: row;
    margin-top: 50px;
    /* align-items: center; */
}

.header-para-div2{
    width:60%;
}

.header-para-div2 p{
    font-size: 24px;
}

.header-para-div3 p{
    font-size:24px;
}

.flex-content{
    flex-direction:row-reverse;
    margin-top:200px;
}

@media (max-width: 1200px){
    .header-flex h1{
        font-size:26px;
    }
    .header-para-div1 img{
        max-width: 100%;
    }
}

@media (max-width: 900px){ 
    .header-div{
        margin:20px 0;
    }
    
    .header-heading{
        margin: 20px 0;
    }
    
    .header-heading h1{
        font-size:30px;
    }
    
    .header-body{
        flex-direction: column;
        margin: 30px 0;
    }
    
    .header-flex{
        width: 80%;
        margin: 30px;
    }
    
    .header-flex h1{
        font-size:26px;
        margin: 10px 0;
    }
     
    .header-para{
        padding: 20px;
    }
    
    .header-para-heading{
        margin:20px 0;
    }
    .header-para-heading h1{
        font-size: 26px;
    }
    
    .header-para-div1{
        margin:20px auto;
        width: 100%;
    }
    
    .header-para-div1 h1{
        font-size: 20px;
    }
    .header-para-div1 p{
        font-size: 14px;
        margin:0 auto;
    }
    
    .header-para-div1 img{
        max-width: 80%;
        border-radius: 15px;
        margin: 10px auto;
    }
    
    .header-para-div2 p span{
        font-size: 22px;
    }
    
    .header-para-content{
        display: flex;
        flex-direction: column;
        margin: 20px auto;
        /* align-items: center; */
    }
    
    .header-para-div2{
        width:100%;
    }
    
    .header-para-div2 p{
        font-size: 16px;
    }
    
    .header-para-div3 p{
        font-size:16px;
    }
    
    .flex-content{
        flex-direction:column;
        margin-top:100px;
    }
}