.footer{
    background-color: #835FF8;
    color: #fff;
    padding: 20px 50px;
    margin-top:50px;
}

.footer i{
    color: #fff;
}

.footer h1{
    font-size:30px;
    font-weight: 500;
}

.footer-body{
    display: flex;
    justify-content: space-between;
}

.footer-description{
    display: flex;
    justify-content:center;
    align-items: center;
}

.footer-description{
    padding:0 10px;
}

.footer-bottom{
    background-color: #3B3B3B;

}

.footer-para{
    margin: 0;
    text-align: center;
    padding: 20px 0;
    color: white;
}

.footer-bottom-2{
    display: flex;
    flex-direction: row;
    justify-content:space-around;
    color: #fff;
    padding:60px 20px;
}

.footer-container li{
    margin:10px 30px 10px 0;
    font-size: 18px;
    color: #1BD697;
}

.footer-container h1{
    font-size:26px;
    font-weight: bold;
    margin:20px 0;
}

.footer-bottom img{
    max-width: 10%;
}

.footer-bottom-down a{
    display: flex;
    justify-content: flex-end;
    padding:0 20px 20px ;
}

@media (max-width: 1200px) {
    .footer-container li{
        font-size: 14px;
    }
    .footer-bottom-down img{
        max-width: 22%;
    }
}

@media (max-width:800px){
    .footer{
        padding: 20px;
        margin-top:50px;
    }
    
    .footer h1{
        font-size:24px;
        text-align: center;
    }
    
    .footer-body{
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }
    
    
    .footer-description{
        padding:20px 10px;
    }
    
    .footer-bottom{
        background-color: #3B3B3B;
    
    }
    
    .footer-para{
        margin: 0;
        text-align: center;
        padding: 20px 0;
        color: white;
    }
    
    .footer-bottom-2{
        flex-direction: column;
        padding:30px;
    }
    
    .footer-container li{
        font-size: 14px;
    }
    
    .footer-container h1{
        font-size:22px;
    }
}