.whyvi{
    margin:100px 0 100px 0;
    text-align: center;
}

.whyvi-heading{
    text-align: center;
    width:50%;
    margin: 30px auto;
}

.whyvi-heading h3{
    font-weight: 400;
    font-size: 16px;
}


.whyvi-heading p, h3{
    color:#4E4E4E;
}

.whyvi-head-img img{
    max-width: 100%;
}

.whyvi-head-img{
    position: absolute;
}

.whyvi-body{
    position:relative;
}

.whyvi-video img{
    margin: 60px auto;
    max-width: 40%;
}

.whyvi-flex{
    display:flex;
    flex-direction: row;
    justify-content:space-around
}

.whyvi-content{
    margin:30px;
    padding:40px;
}

.whyvi-content img{
    height:90px;
    margin: 20px auto;
}

.whyvi-content p{
    font-size: 14px;
}

.whyvi-content h1{
    font-size: 18px;
}

.whyvi-heading h1{
    font-size:41px;
    font-weight:bold;
    margin:20px 0;
}
.whyvi-heading p{
    width: 70%;
    margin: 20px auto;
}

.whyvi-content h1 span{
    color: #A4A4A4;
    margin-right: 10px;
}

.whyvi-content h1{
    font-size:20px;
    font-weight: bold;
    margin:20px 0;
}

@media (max-width:800px){
    .whyvi{
        margin:30px 0;
        text-align: center;
    }
    
    .whyvi-heading{
        width:80%;
    }
    
    .whyvi-heading h3{
        font-weight: 400;
        font-size: 16px;
    }
    
    
    .whyvi-head-img img{
        max-width: 100%;
    }
    
    .whyvi-head-img{
        position: absolute;
    }
    
    .whyvi-body{
        position:relative;
    }
    
    .whyvi-video img{
        margin: 20px auto;
        max-width: 80%;
    }
    
    .whyvi-flex{
        display:flex;
        flex-direction: column;
        justify-content:space-around
    }
    
    .whyvi-content{
        margin:20px;
        padding:40px;
    }
    
    .whyvi-content img{
        margin: 80%;
        margin: 20px auto;
    }
    
    .whyvi-content p{
        font-size: 12px;
    }
    
    .whyvi-content h1{
        font-size: 16px;
    }
    
    .whyvi-heading h1{
        font-size:26px;
        font-weight:bold;
        margin:20px 0;
    }
    .whyvi-heading p{
        width: 80%;
        margin: 20px auto;
    }
    
    .whyvi-content h1 span{
        color: #A4A4A4;
        margin-right: 10px;
    }
    
    .whyvi-content h1{
        font-size:20px;
        font-weight: bold;
        margin:20px 0;
    }
}