.whyus img{
    max-width: 100%;
    /* position: absolute; */
    position: relative;
}

.whyus-body{
    margin: 60px 50px;
}

.whyus-top-header{
    width:45%;
    margin-left:60px ;
}

.whyus-top-header h1{
    font-size:35px;
    font-weight: bold;
    margin: 10px 0 30px 0;
}

.whyus h1{
    /* position: relative; */
    /* text-align: center; */
    /* top:300px; */
}

.whyus-flex{
    display: flex;
    justify-content: space-around;
}

.whyus-content{
    width: 40%;
    /* padding: 30px; */
}

.whyus-bottom-head span{
    color: #A4A4A4;
    margin-right: 20px;
}

.whyus-bottom-head h1{
    font-size: 20px;
}

.whyus-heading{
    text-align: center;
}

.whyus-content img{
    margin:20px 0;
}

.whyus-bottom-head h1{
    font-weight: bold;
}

@media (max-width:800px){
    .whyus img{
        padding-top: 60px;
    }

    .whyus-body{
        margin: 30px;
    }
    
    .whyus-top-header{
        width:100%;
        margin:20px ;
    }
    
    .whyus-top-header h1{
        font-size:25px;
        font-weight: bold;
        margin: 20px auto;
    }
    
    .whyus-flex{
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }
    
    .whyus-content{
        width: 80%;
        margin: 20px auto;
        /* padding: 30px; */
    }
    
}