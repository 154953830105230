.whyservices{
    padding: 40px;
}

.whyservices-heading{
    text-align: center;
    margin: auto auto;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50vh;
}
.whyservices-heading h1{
    font-size:76px;
    font-weight: bold;
}

.whyservices-head-top{
    padding:50px 0;
    margin: 0 auto;
}

.whyservices-head-top{
    position: relative;
}

.whyservices-img{
    max-width: 10%;
    position: absolute;
    right: 0;
}

.whyservices-div-head{
    padding:10px 30px;
    /* background-color: #EFEFEF; */
    text-align: center;
    width: 50%;
    border-radius: 10px;
    /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
    background-color: #EFEFEF;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}


.whyservices-flex{
    display: flex;
    flex-direction: row;
    margin: 50px 0;
}

.new-whyservices-flex{
    justify-content:space-around;
    
}

.whyservices-content{
    transition: transform 1s;
    width:26%;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 10px;
    padding:30px;
    margin:20px;
    background-color: #fff;
}

.whyservices-content:hover{
    transform: scale(1.1);
}

.whyservices-content h1{
    text-align:center;
    font-size: 32px;
    font-weight: 600;
    margin:20px 0;
}

.whyservices-div-head h1{
    font-size:45px;
    font-weight: bold;
}

@media (max-width: 1200px){
    .whyservices-heading{
        height: auto;
    }
}
@media (max-width: 1000px) {
    .whyservices-heading{
        height: 30vh;
    }
    
}

@media (max-width: 800px){
    .whyservices{
        padding: 20px;
    }
    
    .whyservices-heading{
        margin: auto auto;
        height: 40vh;
    }
    .whyservices-heading h1{
        font-size:38px;
    }
    
    .whyservices-head-top{
        padding:30px 10px;
    }

    
    .whyservices-img{
        max-width: 20%;
    }
    
    
    .whyservices-flex{
        flex-direction: column;
        margin: 30px 0;
    }
    
    .whyservices-content{
        width:80%;
        padding:30px;
        text-align: center;
    }
    
    .whyservices-content h1{
        text-align:center;
        font-size: 24px;
        margin:20px 0;
    }
    
    .whyservices-div-head h1{
        font-size:26px; 
    }

    .whyservices-div-head{
        width: 100%;
    }
}