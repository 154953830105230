.contact-form{
    display:flex;
    flex-direction:row;
    /* justify-content:center; */
    justify-content: space-around;
    align-items:center;
    position: relative;
    padding:60px 30px;
}

.new-contact-form-div{
    width:100%;
}

.contact-form-div{
    width:60%;
}

/* .contact-form-new {
    margin:0 auto;
} */

.contact-form-new .contact-form-div{
    margin: 0 auto;
}
.contact-form-new .close{
    font-size: 22px;
}

.contact-design{
    width: 40%;
}

.contact-design img{
    max-width: 70%;
    height: 100%;
    position:absolute;
    top: -80px;
    left: -30px;
}

.contact-form{
    /* display:flex;
    flex-direction:row; */
    margin:50px auto;
    padding:60px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius:20px;
    width:50%;
}
.contact-form-new{
    /* display:flex;
    flex-direction:row; */
    /* margin:50px auto; */
    padding:20px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius:0px;
    width:100%;
}

#form-rig input{
    padding:10px 20px;
    font-size: 16px;
    border: none;
    border-radius: 10px 10px 50px 10px;
    background-color: #ECECEC;
    margin:10px 0;
    width: 90%;
}

#form-rig textarea{
    width: 90%;
    padding:10px 20px;
    font-size: 16px;
    border: none;
    border-radius: 10px 10px 50px 10px;
    background-color: #ECECEC;
    margin:10px 0;
    
}

#form-rig button{
    background-color: #835FF8;
    font-weight: bold;
    border-radius: 5px 5px 25px 5px;
    color: #fff;
    font-size: 18px;
    border:none;
    padding: 10px 30px;
    margin:30px 0;
}

.new-spot-rig{
    width: 90%;
    padding:10px 20px;
    font-size: 16px;
    border: none;
    border-radius: 10px 10px 50px 10px;
    background-color: #ECECEC;
    margin:10px 0;
    color: #7E7A7A;
}
.new-spot-rig select{
    padding:10px 20px;
    font-size: 16px;
    background-color: #ECECEC;
    margin:0px 20px;
    color: #000000;
}

.title-rig{
    font-size: 50px;
    text-align: center;
    font-weight: 500;
}

#form-rig p{
    font-size: 16px;
    text-align: center;
    margin: 10px 0 30px 0;
}

@media (max-width:1200px){
    .contact-design img{
        max-width: 50%;
        height: 80%;
        position:absolute;
        top: -80px;
        left: -30px;
    }
}

@media (max-width:800px){
    .contact-form{
        padding:20px;
        justify-content: center;
    }
    
    .contact-form-div{
        width:100%;
    }

    .contact-design img{
        /* display: none; */
        max-width: 40%;
        height: 60%;
        position:absolute;
        top: -50px;
        left: -30px;
    }
    
    .contact-form{
        /* display:flex;
        flex-direction:row; */
        padding:20px;
        width:80%;
    }
    
    #form-rig input{
        padding:10px 20px;
        margin:10px 0;
        width: 100%;
    }
    
    #form-rig textarea{
        width: 100%;
        padding:10px 20px;
        margin:10px 0;
        
    }
    
    .title-rig{
        font-size: 25px;
    }
    
    #form-rig p{
        font-size: 12px;
    }

    .popup-content {
        margin: auto;
        background: #fff;
        width: 90%;
        padding: 5px;
        border: 1px solid #d7d7d7;
    }
}