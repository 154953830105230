.hero-banner-img img{
    width:100%;
}

.hero-banner-header{
    height:170vh;
}

.hero-banner-img img{
    position: absolute;
    
}
.hero-pop{
    top: 400px;
    position: relative;
    width: 30%;
    display: flex;
    margin: 0 0 0 auto;
}

.hero-banner{
    display:flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    position:relative;
    margin: 0px auto;
    border-radius:10px;
    background-color: #FFFF;
    top: 450px;
    /* flex-direction: row;
    justify-content: space-around; */
}

.hero-banner-div{
    display: flex;

}

.hero-banner-content{
    width:50%;
    padding: 40px 40px 40px 90px;
}

.hero-banner-content h1{
    font-size: 46px;
    font-weight:bold;
    margin:20px 0;
}

.hero-boy{
    width: 50%;
    /* position: relative; */
}
.hero-boy img{
    width:90%;
    height: 100%;
    /* position: absolute; */
    /* margin-top: -40px; */
}

.hero-banner-button button{
    padding:12px;
    font-size: 14px;
    background-color: #8C52FF;
    border: none;
    color:white;
    border-radius: 10px;
    width: 200px;
    margin: 10px 0;
}

.hero-banner-qr img{
    max-width: 70%;

}

.hero-banner-qr p{
    font-size:10px;
    margin:5px;
}

.hero-banner-gplay img{
    max-width: 70%;
    margin: 10px 0;
}

.hero-banner-gplay {
    margin: 0 30px;
}
.hero-banner-gplay p{
    font-size: 10px;
    margin: 10px 0;
}

.hero-banner-gplay-para {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.book-spot{
    padding:12px;
    font-size: 14px;
    background-color: #8C52FF;
    border: none;
    color:white;
    border-radius: 10px;
    width: 200px;
    margin: 10px 0;
}

@media (max-width:1500px){
    .hero-banner-content h1{
        font-size: 30px;
    }
    .book-spot{
        padding:10px;
        font-size: 10px;
        width: 150px;

    }
    .hero-banner-button button{
        padding:10px;
        font-size: 10px;
        width: 150px;
    }
    .hero-pop{
        top: 380px;
    }
}

@media (max-width: 1200px) {
    
    .hero-banner-header{
        height:130vh;
    }
    .hero-banner{
        top: 320px;
    }
    .hero-banner-content h1{
        font-size: 20px;
    }
    .book-spot{
        padding:10px;
        font-size: 6px;
        width: 100px;
    }
    .hero-banner-button button{
        padding:10px;
        font-size: 6px;
        width: 100px;
    }
    .hero-pop{
        top: 300px;
    }
}
@media (max-width: 1000px) {
    .hero-banner-img img{
        padding-top: 70px;
    }
    .hero-banner-header{
        height:55vh;
    }
    .hero-banner{
        top: 400px;
    }
    .hero-pop{
        top: 330px;
    }
    
}

@media (max-width: 800px) {
    .hero-banner-img img{
        padding-top: 70px;
    }
    .hero-banner-header{
        height:100vh;
    }
    .hero-banner{
        top: 210px;
        flex-direction: column;
        /* justify-content: space-around; */
    }
    
    
    .hero-banner-div{
        display: flex;
    
    }
    
    .hero-banner-content{
        width:100%;
        padding: 20px;
    }
    
    .hero-banner-content h1{
        font-size: 20px;
        margin:10px 0;
    }
    
    .hero-boy{
        width: 100%;
    }
    .hero-boy img{
        width:50%;
        height: 100%;
        margin: 0 auto;
    }
    
    .book-spot{
        padding:8px;
        font-size: 8px;
        width: 100px;
        margin: 10px 0;
    }
    .hero-banner-button button{
        padding:8px;
        font-size: 8px;
        width: 100px;
        margin: 10px 0;
    }
    
    .hero-banner-qr img{
        max-width: 80%;
    
    }
    
    .hero-banner-qr p{
        font-size:10px;
        margin:5px;
    }
    
    .hero-banner-gplay img{
        max-width: 70%;
        margin: 10px 0;
    }
    
    .hero-banner-gplay {
        margin: 0 20px;
    }
    .hero-banner-gplay p{
        font-size: 8px;
        margin: 5px 0;
    }
    .hero-pop{
        top: 155px;
        right:20px;
    }
    .book-spot{
        padding:6px;
        font-size: 8px;
        width: 80px;
        margin: 10px 0;
    }
}