.FAQ-rig{
    text-align: center;
    margin: 30px 0;
}

.FAQ-rig h1{
    font-size:36px;
    font-weight: bold;
}

.FAQ-rig-div{
    background-color: #ffffff;
    padding:60px;
    margin: 30px 0;
}

.accordion__button{
    background-color: #fff;
}